import { ROUTES } from './app-routes.constant';

export const enum NotAllowedTitle {
  DEFAULT = "You don't have permission",
  CHROME = 'Browser not supported',
}

export const enum NotAllowedMessage {
  DEFAULT = "Looks like you don't have access to this page. " +
  'Please contact your system administrator to help manage or change your permissions.',
  CHROME = 'Your browser does not meet new standards for websites. ' +
  'Please download Chrome for the best experience.',
}

export const APP_ERROR_MESSAGE = {
  CLIENT_ERROR_MSG:
    'NON-PROD: Dev team check console for errors.',
  SERVICE_ERROR_MSG:
    'Service unavailable, please contact system administrator.',
  STICKER_SERVICE_ERROR_MSG: 'Sticker Service is not available',
  INVOICE_SERVICE_ERROR_MSG: 'Invoice Service is not available',
  INVOICE_NOT_FOUND_MSG: 'One or more of the requested invoices are not yet available. Please try later.',
  INVOICE_OTHER_ERROR_MSG: 'Something went wrong generating one or more invoices. Please try again.',
};

export const UserAuthorization = {
  DASHBOARD: 0
};

export const Cookie = {
  engageSessionId: 'engageSessionId'
}

export const tabs = {
  home: 'Home',
  dealerdaily: 'DealerDaily',
  pricing: 'Pricing',
  faq: 'FAQ',
  get_in_touch: 'GetInTouch'
}

export const priceOptions = ['1,500', '2,000', '2,500'];
export const priceNow = ['700', '1,100', '1,520'];
export const newVehiclesSoldPerYear = ['1 - 500', '501 - 1,000', 'Over 1,000'];

export const SecurityQuestions = [
  'What is your mother\'s maiden name?',
  'What is your city of your birth?',
  'What is the name of your elementary school?',
  'What is your favorite TV show?',
  'What was your high school mascot?'
];

export const Registration = {
  address: {
    name: 'Address',
  },
  address2: {
    name: 'Address 2'
  },
  brand: {
    brand: 'Brand'
  },
  city: {
    name: 'City'
  },
  company: {
    name: 'Company'
  },
  country: {
    name: 'Country'
  },
  fax: {
    name: 'Fax'
  },
  firstName: {
    name: 'First name'
  },
  initial: {
    name: 'Initail'
  },
  lastName: {
    name: 'Last name'
  },
  mobileNumber: {
    name: 'Mobile number'
  },
  password: {
    name: 'Password',
    errorMsg: 'Invalid Password'
  },
  phone: {
    name: 'Phone',
    errorMsg: 'Invalid Phone number'
  },
  rePassword: {
    name: 'Re-password'
  },
  registerEmail: {
    name: 'Email',
    errorMsg: 'Invalid email'
  },
  state: {
    name: 'State'
  },
  title: {
    name: 'Title'
  },
  tmnaContactPerson: {
    name: 'TMNA Contact person'
  },
  zipCode: {
    name: 'Zip code',
    errorMsg: 'Invalid Zip code'
  },
  mailDrop: {
    name: 'Maildrop'
  },
  peopleSoftId: {
    name: 'People Soft ID',
    errorMsg: 'Invalid People Soft ID'
  },
  reviewerRole: {
    name: 'Reviewer Role'
  },
  tmnaDept: {
    name: 'TMNA Department'
  }
}

export const InititateReviewConst = {
  warnings: {
    leader: `You only assigned one Review Leader. At least two Review Leaders are recommended.
    Click "OK" to proceed with one Review Leader. Click "Cancel" to go back and add another Review Leader.`
  }
}

export const UploadContent = {
  UploadContentSuccess: 'Content Uploaded successfully',
  UploadContentError: 'File size error.  Please provide Review # to Project Support team at ProjectReview.Support@toyota.com or use the Need Help Link at the bottom right of any Project Review page.'
}

export const PublishReviewConst = {
  dateWarning: 'Please note: Allow 5 working days if Legal review is required.',
  notificationWarning: 'Before publishing this Review, check the following and edit as needed:',
  emailNotificationWarn: `Uncheck participants if you do not want them to receive email notifications.
  (To completely remove their access to this Review, return to the Step 1 page.)`,
  deadlineWarning: 'Warning: due date is less than standard Review time required. Please write a short description of why earlier deadline is necessary below.',
  reminders: [
    { text: 'No Reminder', value: 0 },
    { text: '5 minutes before', value: 5 },
    { text: '10 minutes before', value: 10 },
    { text: '15 minutes before', value: 15 },
    { text: '30 minutes before', value: 30 },
    { text: '1 Hour Before', value: 1 * 60 },
    { text: '2 hours before', value: 2 * 60 },
    { text: '3 hours before', value: 3 * 60 },
    { text: '4 hours before', value: 4 * 60 },
    { text: '5 hours before', value: 5 * 60 },
    { text: '6 hours before', value: 6 * 60 },
    { text: '7 hours before', value: 7 * 60 },
    { text: '8 hours before', value: 8 * 60 },
    { text: '9 hours before', value: 9 * 60 },
    { text: '10 hours before', value: 10 * 60 },
    { text: '11 hours before', value: 11 * 60 }
  ],
  emailSubject: () => {
    return `Review Required: ProjectName, Round, Due DueDate by DueTime`
  },
  emailText: (role = ROLES.REVIEW_ADMIN, userComments?) => {
    let message = 'The following Review project has been initiated and requires your feedback:';
    let comments = `If you have questions or need assistance,
    please contact me at the email address below.`;
    if (role === ROLES.AGENCY) {
      message = 'The following Review project has been published and is with Reviewers';
    }
    if (userComments) {
      comments = userComments
    }
    return `THIS IS AN AUTOMATED MESSAGE, PLEASE DO NOT REPLY.<br><br><br>Dear [ReviewerName],<br><br>
    ${message}<br><br>
    :ReviewNumber | Round
    :Round<br>
    :ProjectName<br
    >Due: :DueDate,
    :DueTime<br><br>
    Please use this link to access the
    Review:<br>${window.location.origin}/${ROUTES.INITIATE_REVIEW}?ReviewID=:ReviewID<br><br>
    ${comments}<br><br>
    Thank you,<br><br>
     :FirstName :LastName <br>
     :Email<br>
     :phone<br><br>
     :Company<br>
     :address1<br>
     :City, :State :zip<br>
     :Country<br>`
  }
}

export const ROLES = {
  ADMIN: 'Project Review Super User',
  REVIEW_ADMIN: 'Project Review Review Leader',
  TMS_REVIEWER: 'Project Review Reviewer',
  AGENCY: 'Project Review Agency/Graphics Partner',
  LEGAL_REVIEWER: 'Project Review Legal Reviewer',
  PP_REVIEWER: 'PP Reviewer',
  REVIWERES: 'Reviewers',
};

export const PreviewNotification = {
  emailSubject: 'Review ready for content upload',
  emailMessage: 'The following Review project has been initiated and requires you to upload document to our system:',
  mailCommnet: 'If you have questions or need assistance, please contact me at the email address below. Thank you,',
  mailMessage: (comments = '', emailMsg = '') => {
    const message = emailMsg || PreviewNotification.emailMessage;
    return `THIS IS AN AUTOMATED MESSAGE, PLEASE DO NOT REPLY.<br><br><br>
    Dear [ReviewerName],<br><br>
    ${message}<br><br>
    :ReviewNumber | Round
    :Round<br>
    :ProjectName<br>
    <br>Please use this link to access the
    Review:<br>${window.location.origin}/${ROUTES.INITIATE_REVIEW}?ReviewID=:ReviewID<br><br>
    ${comments}<br><br>
    Thank you,<br><br>
     :FirstName :LastName <br>
     :Email<br>
     :phone<br><br>
     :Company<br>
     :address1<br>
     :City, :State :zip<br>
     :Country<br>`
  }
}

export const ReviewLeaderNotify = {
  emailSubject: 'Review initiated',
  emailMessage: 'The following Review project has been initiated. It is ready for you to check and publish:'
}

export const PreviewNotificationDialog = {
  title: 'The Notification has been sent',
  message: '',
  Agency_User: {
    title: 'Review Leader Notification Sent',
    message: 'An email notification that the Review is initiated has been sent to the Review Leader(s) you selected.'
  }
}

export const TIME_ZONE = {
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago'
}

export const REVIEW_STATUS = {
  REVIEW_COMPLETE_STATUS: 'Review completed',
  REVIEW_PENDING_STATUS: 'Pending reviews',
  ROUND_CLOSED: 'Round closed',
  PUBLISHED_FOR_REVIEW: 'Published for review',
  AWAITING_CONTENT: 'Awaiting content',
  AGENCY_CLOSED_REVIEW: 'Agency closed reviews',
  CONTENT_UPLOADED: 'Content uploaded',
  INITIATED_BY_AGENCY: 'Initiated and submitted by agency',
  HOTSPOT_ADDED: 'Hot spots added'
}

export const APPROVAL_STATUS = {
  APPROVED_AS_IS : 'Approved as is',
  APPROVED_WITH_COMMENTS_AS_INDICATED : 'Approved with comments as indicated',
  NOT_APPROVED : 'Not approved, must review again',
  LEGAL_ADVICE_GIVEN : 'Legal advice given' 
}

export const FILE_TYPES = {
  PDF: 'application/pdf',
  MSWORD: 'application/msword',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ZIP: 'application/zip',
  COMPRESSED: 'application/x-compressed',
  XZIPCOMPRESSED: 'application/x-zip-compressed',
  XZIP: 'multipart/x-zip',
  IMG: 'image/jpeg',
  PNG: 'image/png',
  JPG: 'image/jpg',
  MP3: 'audio/mpeg',
  WAV: 'audio/wav',
  AAC: 'audio/vnd.dlna.adts',
  MP4: 'video/mp4',
  MOV: 'video/quicktime'
}

export const ACCEPTED_FILE_TYPES = '.pdf,.doc,.xls,.docx,.xlsx,.ppt,.jpg,.jpeg,.pptx,.png,.mp3,.wav,.aac,.mp4,.mov';

export const MANAGE_REVIEW_ACTIONS = {
  StartNewRound: {
    Title: 'StartNewRound',
    DialogTitle: 'Start New Round of Review',
    DialogMessage: 'The new Review round is open.',
    DialogActionText: 'Continue',
    DialogActionType: 'NewRoundContinue',
    Action: 'New round'
  },
  ReOpenRound: {
    Title: 'ReOpenRound',
    DialogTitle: 'Reopen Review',
    DialogMessage: 'The Review is now reopened.',
    DialogActionText: 'Continue',
    DialogActionType: 'ReopenRoundContinue',
    Action: 'Reopen round'
  },
  Archive: {
    Title: 'Archive',
    DialogTitle: 'Move Off Active List',
    DialogMessage: 'The Review is moved off the active list.',
    DialogActionText: 'Continue',
    DialogActionType: 'ArchiveContinue',
    Action: 'Archive'
  },
  Delete: {
    Title: 'Delete',
    DialogTitle: 'Delete Review',
    DialogMessage: 'The Review is deleted.',
    DialogActionText: 'Continue',
    DialogActionType: 'DeleteContinue',
    Action: 'Delete'
  },
  AddRemoveReviewers: 'AddRemoveReviewers',
  ChangeDeadline: {
    Title: 'ChangeDeadline',
    Action: 'Chnage deadline'
  },
  CloseRound: {
    Title: 'CloseRound',
    DialogTitle: 'Close Review',
    DialogMessage: 'Review is closed',
    DialogActionText: 'Continue',
    DialogActionType: 'CloseRoundContinue',
    Action: 'Close round'
  },
  Reavtivate: {
    Title: 'Reavtivate',
    DialogTitle: 'Reactivate Review',
    DialogMessage: 'The Review is reactivated.',
    DialogActionText: 'Continue',
    DialogActionType: 'ReavtivateContinue',
    Action: 'Activate Review'
  }
}

export const USER_DETAILS = {
  userId: 101
}

export const BRAND = {
  Toyota: 'Toyota',
  Lexus: 'Lexus'
}

export const paginationOptions = {
  manage_reviews: [50, 100, 200, 500],
  pending_reviews: [50, 100, 200, 500],
  published_reviews: [50, 100, 200, 500],
  completed_reviews: [50, 100, 200, 500],
  closed_reviews: [50, 100, 200, 500],
  awaiting_content_reviews: [50, 100, 200, 500],
  agency_closed_reviews: [50, 100, 200, 500],
  search_list: [50, 100, 200],
  UserList: [50, 100, 200],
  manage_models:[50, 100, 200]
}

export const DEFAULT_PAGE_SIZE = 50;
export const MY_REVIEW_DEFAULT_PAGE_SIZE = 100;

export const MenuList = {
  searchReview: 'Search Review',
}

export const tableHeaders = {
  userList: ['name', 'category', 'department', 'status'],
  manageReview: ['select', 'reviewnumber', 'responsedeadline', 'projectname', 'roundnumber', 'status', 'action'],
  commonTableHeader: ['reviewnumber', 'projectname', 'roundnumber'],
  completedAndPending: ['reviewnumber', 'responsedeadline', 'projectname', 'roundnumber', 'commentstatus'],
  holidayList:['holidayDetails','DeleteHoliday'],
  modelList:['reviewmodel','brand','sortorder','isactive']
}

export const searchRadioFilters = {
  includeArray: ['All Reviews', 'Active Reviews', 'Archived Reviews'],
  searchArray: ['All Reviews', 'Only Campaigns'],
  keywordOptionArray: ['Search Review comments', 'Search comments & proof PDFs']
}

export const Upload_Content = {
  STATUS: 'Content uploaded',
  AGENCY_STATUS: 'Initiated and submitted by agency'
}

export const WARNINGS = {
  ReviewLeaderRequired: 'Please select Review Leader. At least two Review Leaders are recommended.',
  RequiredField: 'Please fill all the required fields',
  UnknownError: 'Unknown error. Contact Administrator',
  FileUploadWarning: 'File size error.  Please provide Review # to Project Support team at ProjectReview.Support@toyota.com or use the Need Help Link at the bottom right of any Project Review page.',
  SaveDataWarning: 'Error! Unable to save data.',
  PublishedDateWarning: 'Published From date should be less than Published To date'
}

export const TAB_PROFILE_MENU = {
  UserManagement: 'user_management',
  Profile: 'profile',
  Logout: 'logout',
  HolidayLists:'holiday_lists',
  ManageModel: 'manage_model'
}

export const PublicRoute = 'PublicRoute';

export const MY_REVIEW_STATUS = {
  pending_reviews: 'Pending Review',
  completed_reviews: 'Completed Active Review',
  closed_reviews: 'Closed Review',
  awaiting_content_reviews: 'Reviews Awaiting Content',
  published_reviews: 'Published Reviews',
  agency_closed_reviews: 'Closed Active Reviews'
};

export const StatusCode = {
  Success: 200
}

export const UploadStatus = {
  Success: 'success',
  Error: 'error',
  NotStarted: 'notStarted'
}

export const FilesWarning = {
  NoName: 'No files name found. Please provide valid file name'
}

export const ModelsLabel = ['----- LEXUS ------', '----- TOYOTA ------'];

export const brands = ['Lexus','Toyota'];


export const LabelName = {
  ProjectReview: 'PROJECT REVIEW',
  ProjectReviewSubtitle: 'Confidential Legal/Privileged',
}

export const ReviewerCopyDocumentLabel = {
  OriginalPdf: 'Original PDF',
  Pdf: 'PDF with hot spots',
  AllReviewComments: 'All Reviewer Comments',

}

export const UploadConsolidatedDocumentLabel={
  UploadConsolidatedDocumentName:'Upload Consolidated Document'
}

export const ExcelHeaders = {
  REVIEWCOMPLETED: 'REVIEW COMPLETED?'
};

export const ReviewSorting = {
  pendingReview: 'pending_review',
  completedReview: 'completed_review',
  closedReview: 'closed_review',
  manageReview: 'manage_review',
  agencyClosedReview: 'agency_closed_review',
  publishedReview: 'published_review',
  agencyAwaitingReview: 'agency_awaiting_review'
}
